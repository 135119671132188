import { registerApplication, start } from "single-spa";

const usrToken =
  "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjZiODE1MTgwMWY3NGRmMTgwZTU5ZTJiOTc2YjNmMDIxYjAzODViZDQ4M2NhYjliMzRkYjM4ODY3YTJiOTJhYTljOWIxNzdhMTRkMWNhZGExIn0.eyJhdWQiOiIxIiwianRpIjoiNmI4MTUxODAxZjc0ZGYxODBlNTllMmI5NzZiM2YwMjFiMDM4NWJkNDgzY2FiOWIzNGRiMzg4NjdhMmI5MmFhOWM5YjE3N2ExNGQxY2FkYTEiLCJpYXQiOjE1OTY0ODM0OTMsIm5iZiI6MTU5NjQ4MzQ5MywiZXhwIjoxNjI4MDE5NDkzLCJzdWIiOiIxMzQiLCJzY29wZXMiOltdfQ.ZHglYKf5_k-kqN6X0laJRnZT_aW2K3eFUUYTRXGVa5PQuqrySvA7XZ-0V51xz87SsDEFs9j9Y9Gk15w7-a1XCOqswtWrzuQXLE7jgGsefmuUr3SeEMx_sUPlAvLm8AoMvP03INmQhIl8tGbqCD1AKhQT2E2N0863_3lcNbBzbs065It8Nabz7kSu6amF61ZjVXdV_PSHW2exI2Lh50nj2GRbsaqGYfBBZXpZLvjGKBR_IWvg4WVEr3mjJSpfLJdOTrCAm_bOKk5uxfWKWBbsfGawoeiaz2UNFruXGRWUdNiCHWgLofQKP2DpDlyz5ZBsYQyUzCMRDAT5Sgz7f0Btt0TpOhhBxio-fVncfUt2E6L1E7VyLrcebAi1CSp0f1bUY_ef26ZEVDIUeMKWxgSsNygn5j39zl47i-7X8uhB9Ue7ilf-vXFjWgM0UT6HuuLNFu-flWbXOQ8Kvt7y38QGMguDulG4Y6M2N5d_4xnCuz4JIPrvoaS0lMQTihVH10_h8oKTaz4NG9LpSVyA6rJb9SMqmbg_Its8NJe--N5KLmOAJBhe7sPdWqt27qneNK4I4vmOrX7KAVMHwt1CMYlm2-0IA1maYb2jKV3Feitlt_3qLHXru3binGkQvsaxnVRKyisI8hkvllMkEQ_OF-BHSjZrT0Mx_468sbXLKV56qCM";

registerApplication({
  name: "@ingeo-mf/navbar",
  app: () => System.import("@ingeo-mf/navbar"),
  activeWhen: "/",
  customProps: {
    token: usrToken,
  },
});

registerApplication({
  name: "@ingeo-mf/map",
  app: () => System.import("@ingeo-mf/map"),
  activeWhen: "/map",
  customProps: {
    token: usrToken,
  },
});

registerApplication({
  name: "@ingeo-mf/routes",
  app: () => System.import("@ingeo-mf/routes"),
  activeWhen: "/routes",
  customProps: {
    token: usrToken,
  },
});

registerApplication({
  name: "@ingeo-mf/config",
  app: () => System.import("@ingeo-mf/config"),
  activeWhen: "/config",
  customProps: {
    token: usrToken,
  },
});

registerApplication({
  name: "@ingeo-mf/control-panel",
  app: () => System.import("@ingeo-mf/control-panel"),
  activeWhen: "/control-panel",
  customProps: {
    token: usrToken,
  },
});

registerApplication({
  name: "@ingeo-mf/command",
  app: () => System.import("@ingeo-mf/command"),
  activeWhen: "/command",
  customProps: {
    token: usrToken,
  },
});

start();

// registerApplication({
//   name: "@ingeo-mf/sample",
//   app: () => System.import("@ingeo-mf/sample"),
//   activeWhen: "/sample",
//   customProps: {
//     token: usrToken,
//   },
// });
